body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

button:disabled {
  cursor: not-allowed
}
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{

  --amplify-primary-color: rgb(29, 128, 141);
  --amplify-primary-tint: rgb(108, 156, 31);
  --amplify-primary-shade: gray;

} */
/*   
.bg {
    background-image: url("/images/sunset.jpeg");
  
    height: 100%; 
  
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#root {
  text-align: center;  
} */